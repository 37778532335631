<script>
 import axios from 'axios';
// // // import Switches from "vue-switches";
// // import Swal from 'sweetalert2';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loading from 'vue-loading-overlay';



export default {
  props: ['loadOptionDataParent'],
  components: {
    Loading
  },
  async mounted() {
    
    await this.getDataAnalytic(this.$store.state.authfack.user.token)
  },
  data() {
    return {
        series: [{
                name: "Desktops",
                data: [1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12]
                },
                {
                name: "Desktops1",
                data: [13, 14, 15, 16, 17, 18, 19, 20, 21,22,23,24]
                },
                {
                name: "Desktops2",
                data: [25, 26, 27, 28, 29, 30, 31, 32, 33,34,35,36]
                },
                {
                name: "Desktops3",
                data: [21, 22, 23, 24, 25, 26, 27, 28, 29,30,31,32]
                },
                {
                name: "Desktops4",
                data: [33, 34, 35, 36, 37, 38, 39, 40, 41,42,43,44]
                },
                {
                name: "Desktops5",
                data: [1, 4, 35, 51, 49, 62, 69, 71, 74,75,76,77]
                },
                {
                name: "Desktops6",
                data: [10, 21, 24, 25, 26, 31, 34, 36, 38,41,42,44]
                },
                {
                name: "Desktops7",
                data: [13, 21, 35, 51, 55, 58, 69, 70, 71,72,77,88]
                },
                {
                name: "Desktops8",
                data: [10, 41, 45, 51, 59, 62, 69, 71, 78,83,85,87]
                },
        ],
        seriesStackedRegis:[],
        seriesStackedUnRegis :[],
        seriesBarsKota:[],
        seriesBarsKecamatan:[],
        seriesBarsDesa:[],
        seriesDonut: [44, 55, 13, 33],
        seriesBars: [
        {
          data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365]
        }],
        seriesStackedKota :[],
        seriesStackedDesa :[],
        seriesStackedKecamatan :[],


      chartOptionsKecamatan: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800','#ED5351','#FAB361','#FC03A5','#44c5c9'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight',
              
            },
            title: {
              text: 'Analytic Pelanggan Masuk Per Kecamatan',
              align: 'center'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Des'],
            },
            markers: {
            size: 2,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 1,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3
            }
            }
          
      },
      chartOptionsDesa: {
            chart: {
              height: 300,
              type: 'bar',
              zoom: {
                enabled: false
              }
            },
            colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800','#ED5351','#FAB361','#FC03A5','#44c5c9'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              width: 2,
            },
            title: {
              text: 'Analytic Pelanggan Masuk Per Desa',
              align: 'center'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 1
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Des'],
            },
            markers: {
            size: 2,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3
            }
            },
            legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center', 
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [], 
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800','#ED5351','#FAB361','#FC03A5','#44c5c9'],
                useSeriesColors: false
            },
          }
          
      },
      chartOptionsDonut: {
    labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
    colors: ["#34c38f", "#5b73e8","#f1b44c", "#50a5f1", "#f46a6a"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240
          },
          legend: {
            show: false
          }
        }
      }
    ]
      },
      chartOptionsBars: { 
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#34c38f'],
        grid: {
          borderColor: "#f1f1f1"
        },
        xaxis: {
          categories: [
            "South Korea",
            "Canada",
            "United Kingdom",
            "Netherlands",
            "Italy",
            "France",
            "Japan",
            "United States",
            "China",
            "Germany"
          ]
        }
      },
      chartOptionsBarsKota: { 
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true
        },
        stepSize:1,
        colors: ['#0898ff'],
        grid: {
          borderColor: "#f1f1f1"
        },
        xaxis: {
          categories: []
        }
      },
      chartOptionsBarsKecamatan: { 
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#0898ff'],
        stepSize:1,
        grid: {
          borderColor: "#f1f1f1"
        },
        xaxis: {
          categories: []
        }
      },
      chartOptionsBarsDesa: { 
        chart: {
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#0898ff'],
        stepSize:10,
        grid: {
          borderColor: "#f1f1f1"
        },
        xaxis: {
          categories: []
        }
      },
      //stacked
      chartOptionsStackedKota: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '100%',
              
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            

            stroke: {
              width: 1,
              colors: ['#fff']
            },
           
            xaxis: {
              categories: [],
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val 
                }
              }
            },
            fill: {
              opacity: 0.5,
              
            
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 40
            }
      },
      chartOptionsStackedKecamatan: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '100%'
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
           
            xaxis: {
              categories: [],
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val 
                }
              }
            },
            fill: {
              opacity: 1
            
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 40
            }
      },
      chartOptionsStackedDesa: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '100%'
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            
            xaxis: {
              categories: [],
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val 
                }
              }
            },
            fill: {
              opacity: 1
            
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 40
            }
      },
        

      filter:"",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      percobaan:"asd",
      dataAnalytic:[],
      selectedClientGrup:null,
      optionsClientGrup:[],
      n:0,
      visible:false,
      selectedindex:0,
      message:"test",
      tableData:[],
      isBusy: true,
      fields: [
        {
          label: "Nama",
          key: "name",
          sortable: true,
        },
        {
          label: "Alamat",
          key: "address",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Tanggal Dibuat",
          sortable: true,
          formatter: (value) => {
           
            return moment(value).format("DD-MM-YYYY HH:ss")
          },
        },
        {
          label: "Nomor Handphone",
          key: "phoneNumber",
          sortable: true,
        },
       
        {
          label: "Client Grup",
          key: "clientGrup",
          sortable: true,
        },
        {
          label: "Kota",
          key: "kota",
          sortable: true,
        },
        {
          label: "Kecamatan",
          key: "kecamatan",
          sortable: true,
        },
        {
          label: "Desa",
          key: "desa",
          sortable: true,
        },

        { key: 'actions', label: '' }
         ],
      dataTop: [],
      data:[],
      sortBy: "createdAt",
      sortDesc: true,
      

      topRankedDataKota:{
        kota:"",
        totalPelangganMasuk:0,
        registerPelanggan:0,
        unRegisterPelanggan:0,
        data:[]
      },
      topRankedDataKecamatan:{
        Kecamatan:"",
        totalPelangganMasuk:0,
        registerPelanggan:0,
        unRegisterPelanggan:0,
        data:[]
      },
      topRankedDataDesa:{
        Desa:"",
        totalPelangganMasuk:0,
        registerPelanggan:0,
        unRegisterPelanggan:0,
        data:[]
      },
      loading:false,


    }
  },
  methods: {
    async show() {
      this.$refs.modal.show()
    },
    async getDataAnalytic(token){
      console.log(this.$store.state.authfack.user.token);
     // let devToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJpYXQiOjE3MDI1MjE5NzgsImV4cCI6MTcwMjU1MDc3OCwiYXVkIjoiaHR0cHM6Ly9hcGkuaHViMy5udXNhbnRhcmEubmV0LmlkIiwiaXNzIjoiZmVhdGhlcnMiLCJzdWIiOiI2NDVjOGRhZDFiMTJhYzAwMTJkNmU4N2MiLCJqdGkiOiIwMGZmOTg3Mi0wNzY0LTRmODgtODU4Yi1mZDAxMTBiMTYxMjQifQ.0CTn1_csnRTiOezH2AUqRpzZQsPaQi9EGT4NXqiPDv4"
      
      await axios.get(process.env.VUE_APP_URL_FEATHERS1 + 'customer-applicant?analytic='+token).then(async(response) => { 
        this.dataAnalytic= response
        this.optionsClientGrup=this.dataAnalytic.data.seriesDataClientGrupName  
        this.seriesBars = []
        
        await this.seriesBars.push({
                data: this.dataAnalytic.data.seriesDataClientGrup
            })
       
        this.chartOptionsBars.xaxis.categories = this.dataAnalytic.data.seriesDataClientGrupName
       
       console.log(this.dataAnalytic);
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
    },

    async onchange(event){
     
      this.loading=true
    let countGreater=0
      if(this.selectedClientGrup == null){ 
       this.loading=false
        return null
      }
       
    let a = this.optionsClientGrup.findIndex(checkIndex)
    function checkIndex(series) {
      return series ==  event;
    }
    
    for (let index = 0; index < this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData.length; index++) {
     if(countGreater <= this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData[index].totalPelangganMasuk){
      countGreater = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData[index].totalPelangganMasuk

      this.topRankedDataKota.kota=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData[index].Kota
      this.topRankedDataKota.totalPelangganMasuk=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData[index].totalPelangganMasuk
      this.topRankedDataKota.registerPelanggan=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData[index].RegistedData.length
      this.topRankedDataKota.unRegisterPelanggan=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData[index].UnregisData.length
      this.topRankedDataKota.data = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKotaData[index].DataKota
      //this.topRankedDataKota.data.push()
    }
     
    }
    countGreater =0
    for (let index = 0; index < this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData.length; index++) {
     if(countGreater <= this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData[index].totalPelangganMasuk){
      countGreater = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData[index].totalPelangganMasuk

      this.topRankedDataKecamatan.Kecamatan=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData[index].Kecamatan
      this.topRankedDataKecamatan.totalPelangganMasuk=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData[index].totalPelangganMasuk
      this.topRankedDataKecamatan.registerPelanggan=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData[index].RegistedData.length
      this.topRankedDataKecamatan.unRegisterPelanggan=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData[index].UnregisData.length
      this.topRankedDataKecamatan.data = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatanData[index].DataKecamatan
      
    }
      
    }
    countGreater=0
    for (let index = 0; index < this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData.length; index++) {
     if(countGreater <= this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData[index].totalPelangganMasuk){
      countGreater = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData[index].totalPelangganMasuk

      this.topRankedDataDesa.Desa=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData[index].Desa
      this.topRankedDataDesa.totalPelangganMasuk=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData[index].totalPelangganMasuk
      this.topRankedDataDesa.registerPelanggan=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData[index].RegistedData.length
      this.topRankedDataDesa.unRegisterPelanggan=this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData[index].UnregisData.length
      this.topRankedDataDesa.data = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesaData[index].DataDesa
    }
      
    }
    
       //series bar push data 
        await  this.seriesBarsKota.push({
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayKotaDataRow
          })
          this.chartOptionsBarsKota.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKota
         
          
         await  this.seriesBarsKecamatan.push({
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayKecamatanDataRow
          })
          this.chartOptionsBarsKecamatan.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatan  
                  
          await  this.seriesBarsDesa.push({
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayDesaDataRow
          })
          this.chartOptionsBarsDesa.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesa 
        //series stacked bar push data
        await  this.seriesStackedKota.push({
          name: 'Registered',
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayKotaDataRegisRow
          })
          await  this.seriesStackedKota.push({
          name: 'UnRegistered',
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayKotaDataUnRegisRow
          })
        
        this.chartOptionsStackedKota.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKota
       

        await  this.seriesStackedKecamatan.push({
          name: 'Registered',
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayKecamatanDataRegisRow
          })
          this.chartOptionsStackedKecamatan.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatan

        await  this.seriesStackedKecamatan.push({
          name: 'UnRegistered',
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayKecamatanDataUnRegisRow
          })
        this.chartOptionsStackedKecamatan.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayKecamatan
       
       
        await  this.seriesStackedDesa.push({
          name: 'Registered',
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayDesaDataRegisRow
          })
          this.chartOptionsStackedDesa.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesa


        await  this.seriesStackedDesa.push({
          name: 'UnRegistered',
          data: this.dataAnalytic.data.dataAnalayticPerClientGrup[a].seriesData.arrayDesaDataUnRegisRow
          })
        this.chartOptionsStackedDesa.xaxis.categories = this.dataAnalytic.data.dataAnalayticPerClientGrup[a].Data.arrayDesa
        
       

          this.$refs.chartKota.refresh();
          this.$refs.chartKecamatan.refresh();
          this.$refs.chartDesa.refresh();
          this.$refs.stackChartKota.refresh();
          this.$refs.stackChartKecamatan.refresh();
          this.$refs.stackChartDesa.refresh();
          this.loading=false
         this.$refs.chartKota.$el.scrollIntoView({behavior: 'smooth'});
          
        }, 
      async showModalAnalyticData(a) {
        
          if (a==1){
            this.data=[]
          this.data=this.topRankedDataKota.data
        }
        if (a==2){
         
          this.data=[]
          this.data=this.topRankedDataKecamatan.data
        }
        if (a==3){
         
          this.data=[]
          this.data=this.topRankedDataDesa.data
        }

      await this.squanceGreaterData()
      this.isBusy=false
     
      this.$refs.modalAnalyticData.show()
      },

      async squanceGreaterData(){
        
        console.log("sini");
        
        //this.dataAnalytic.dataAnalayticPerClientGrup.arrayKotaData.length
      }
  },
  watch: {
    'selectedClientGrup': {
      async handler() {
        this.seriesStackedRegis=[],
        this. seriesStackedUnRegis =[],
        this.seriesBarsKota=[],
        this.seriesBarsKecamatan=[],
        this.seriesBarsDesa=[],
        this.seriesDonut= [44, 55, 13, 33],
     
        this.seriesStackedKota =[],
        this.seriesStackedDesa =[],
        this.seriesStackedKecamatan =[]

      },
    },
  },
};
</script>
  
<template>
 
  <div>
  <b-modal id="modal" ref="modal" centered title="Analytic Pelanggan Masuk" title-class="font-18" size="xl" :hide-footer="true" >
    
    <b-tabs content-class="mt-3" fill>
      <Loading  :active="loading"></Loading>
            <div class="row" >
                <div class="col-12">
                    <b-card>
                        <b-card-title class="text-center">Total Keseluruhan Pelanggan Masuk</b-card-title>
                        
                        <b-select v-model="selectedClientGrup" :options="optionsClientGrup" class="float-end" @change="onchange" v-b-popover.hover.top="'Pilih Client Grup Untuk Melihat Detail'">
                          <template #first>
                          <b-select-option :value=null selected>-- Semua Client Grup --</b-select-option>
                        </template>
                        </b-select>
                        <b-card-body>
                          <apexchart
                          class="apex-charts"
                          height="500"
                          type="bar"
                          dir="ltr"
                          :series="seriesBars"
                          :options="chartOptionsBars"
                        ></apexchart>
                      
                        </b-card-body>
                    </b-card>
                </div>
              </div>
              <div class="row" v-if="selectedClientGrup != null">
                   
                    <div class="col-6">
                      <div class="flip-card">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                      <b-card >
                        <b-card-title  class="text-center">Pelanggan Masuk PerKota</b-card-title>
                            <b-card-body>
                              
                             <apexchart
                          class="apex-charts"
                          height="250"
                          type="bar"
                          dir="ltr"
                          ref="chartKota"
                          :series="seriesBarsKota"
                          :options="chartOptionsBarsKota"
                        ></apexchart>
                            </b-card-body>
                      </b-card>
                    </div>
                    <div class="flip-card-back">
                      <b-card >
                        <b-card-title  class="text-center">Presentase konversi PerKota</b-card-title>
                            <b-card-body>
                              <apexchart
                          class="apex-charts"
                          height="250"
                          type="bar"
                          dir="ltr"
                          ref="stackChartKota"
                          :series="seriesStackedKota"
                          :options="chartOptionsStackedKota"
                        ></apexchart>
                            </b-card-body>
                      </b-card>
                      </div>
                  </div>
                    </div>
                    </div>
                    <div class="col-6">
                      <div class="flip-card">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                      <b-card >
                        <b-card-title  class="text-center">Pelanggan Masuk Per Kecamatan</b-card-title>
                            <b-card-body>
                            <apexchart
                          class="apex-charts"
                          height="250"
                          type="bar"
                          dir="ltr"
                          ref="chartKecamatan"
                          :series="seriesBarsKecamatan"
                          :options="chartOptionsBarsKecamatan"
                        ></apexchart>
                            </b-card-body>
                      </b-card>
                    </div>
                    <div class="flip-card-back">
                      <b-card >
                        <b-card-title  class="text-center">Presentase konversi PerKecamatan</b-card-title>
                            <b-card-body>
                             <apexchart
                          class="apex-charts"
                          height="250"
                          type="bar"
                          dir="ltr"
                          ref="stackChartKecamatan"
                          :series="seriesStackedKecamatan"
                          :options="chartOptionsStackedKecamatan"
                        ></apexchart>
                            </b-card-body>
                      </b-card>
                      </div>
                    </div>
                  </div>
                    </div>
                    <div class="col-6">
                     
                    <div class="flip-card">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                      <b-card >
                        <b-card-title  class="text-center">Pelanggan Masuk Per Desa</b-card-title>
                            <b-card-body>
                             <apexchart
                          class="apex-charts"
                          height="250"
                          type="bar"
                          dir="ltr"
                          ref="chartDesa"
                          :series="seriesBarsDesa"
                          :options="chartOptionsBarsDesa"
                        ></apexchart>
                            </b-card-body>
                      </b-card>
                    </div>
                    <div class="flip-card-back">
                      <b-card >
                        <b-card-title  class="text-center">Presentase konversi PerDesa</b-card-title>
                            <b-card-body>
                             
                             <apexchart
                          class="apex-charts"
                          height="250"
                          type="bar"
                          dir="ltr"
                          ref="stackChartDesa"
                          :series="seriesStackedDesa"
                          :options="chartOptionsStackedDesa"
                        ></apexchart>
                            </b-card-body>
                      </b-card>
                      </div>
                    </div>
                    </div>
                  </div>

                  <div class="col-6" style="">
                    <b-card >
                        <b-card-title  class="text-center">Potensial Area Terbanyak</b-card-title>
                            <b-card-body>
                              
                              <div class="row">
                                <div class="col-6">
                                  <div class="card-poping">
                                <div class="card-poping-details">
                                  <p class="text-poping-title mb-1">{{topRankedDataKota.kota}}</p>
                                  <p class="text-poping-body mb-1">Total Pelanggan Masuk : {{topRankedDataKota.totalPelangganMasuk}}  </p>
                                  <p class="text-poping-body mb-1">Registered Pelanggan : {{ topRankedDataKota.registerPelanggan }}      </p>
                                  <p class="text-poping-body">Unregis Pelanggan : {{  topRankedDataKota.unRegisterPelanggan}}    </p>
                                 
                                </div>
                                <button class="card-poping-button" @click="showModalAnalyticData(1)" >Detail</button>
                              </div>
                                </div>
                                <div class="col-6">
                                  <div class="card-poping">
                                <div class="card-poping-details">
                                  <p class="text-poping-title">Kecamatan {{topRankedDataKecamatan.Kecamatan}}</p>
                                  <p class="text-poping-body mb-0">Total Pelanggan Masuk : {{topRankedDataKecamatan.totalPelangganMasuk}}  </p>
                                  <p class="text-poping-body mb-0"> Registered Pelanggan : {{ topRankedDataKecamatan.registerPelanggan }}      </p>
                                  <p class="text-poping-body mb-0"> Unregis Pelanggan : {{  topRankedDataKecamatan.unRegisterPelanggan}}    </p>
                                 
                                </div>
                                <button class="card-poping-button" @click="showModalAnalyticData(2)">Detail</button>
                              </div>
                                </div>
                              </div>
                               
                             <p></p>
                              <div class="card-poping">
                                <div class="card-poping-details">
                                  <p class="text-poping-title">Desa {{topRankedDataDesa.Desa}}</p>
                                  <p class="text-poping-body mb-0">Total Pelanggan Masuk : {{topRankedDataDesa.totalPelangganMasuk}}  </p>
                                  <p class="text-poping-body mb-0">Registered Pelanggan : {{ topRankedDataDesa.registerPelanggan }}      </p>
                                  <p class="text-poping-body mb-0">Unregis Pelanggan : {{  topRankedDataDesa.unRegisterPelanggan}}    </p>
                                 
                                </div>
                                <button class="card-poping-button" @click="showModalAnalyticData(3)">Detail</button>
                              </div>

                             
                             
                            
                            </b-card-body>
                      </b-card>
                  </div>
                  
              </div>
              
    </b-tabs>
  </b-modal>

  <b-modal id="modal-data" ref="modalAnalyticData" size="xl" :hide-footer="true">
    <b-table :busy="isBusy" head-variant="light" :items="data" :fields="fields" responsive="md"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
               
                tbody-tr-class="table"
                show-empty striped>
                <template #empty>
                  <h5>Tidak ada data</h5>
                </template>
                <template #table-busy>
                  <div class="text-center  my-2">
                    <b-spinner class="xs-1" variant="primary"></b-spinner>
                    <strong>Memuat data...</strong>
                  </div>
                </template>
              </b-table>
    
  </b-modal>
</div>
  
  
  
</template>

<style>
.flip-card {
  background-color: transparent;
  width: 33.875rem;
  height: 22.80rem;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #bbb;
  color: white;
  transform: rotateY(180deg);
}


.card-poping {
 width: 12.5rem;
 height: 7.5rem;
 border-radius: 0.625rem;
 background: #f5f5f5;
 position: relative;
 padding: 0.5rem;
 border: 0.125rem solid #c3c6ce;
 transition: 0.5s ease-out;
 overflow: visible;
}

.card-poping-details {
 color: black;
 font-size: 0.8rem;
 height: 100%;

 place-content: center;
}

.card-poping-button {
 transform: translate(-50%, 125%);
 width: 50%;
 border-radius: 0.5rem;
 border: none;
 background-color: #008bf8;
 color: #fff;
 font-size: 0.7rem;
 padding: .5rem 1rem;
 position: absolute;
 left: 50%;
 bottom: 0;
 opacity: 0;
 transition: 0.3s ease-out;
}

.text-poping-body {
 color: rgb(134, 134, 134);
 margin-bottom: 0.25rems;
}

/*Txt*/
.text-poping-title {
 font-size: 0.8rem;
 font-weight: bold;
 margin-bottom: 4px;

}

/*Hover*/
.card-poping:hover {
 border-color: #008bf8;
 box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.card-poping:hover .card-poping-button {
 transform: translate(-50%, 50%);
 opacity: 1;
}

#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:2.5rem;height:2.5rem;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

</style>   
