<script>
import countTo from "vue-count-to";
import axios from 'axios';
import Swal from 'sweetalert2';
import { TimeAgo } from "vue2-timeago";
import "vue2-timeago/dist/vue2-timeago.css";
//const moment = require('moment');


/**
 * Stat component
 */
export default {
    components: {
        countTo,
        TimeAgo,
    },
    data() {
        return {
            series: [{
                data: [1, 3, 5, 7,9]
            }],
            chartOptions: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    },
                    offsetX: 50
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    },
                    chart: {
                        offsetX: 50
                    },
                }
            },
            growthChartOptions: {
                fill: {
                    colors: ["#84f6fa"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    },
                    offsetX: 50
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    },
                    chart: {
                        offsetX: 50
                    },
                }


            },
            orderseries: [99],
            orderRadial: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        },

                    }
                },

            },
            customerseries: [25],
            customerRadial: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        },
                        value: {
                            fontSize: "30px",
                            show: true
                        }
                    }
                },

            },
            testing: [],

            viewPelangganBaru: {
                pelangganBaru: 0,
                growthPelangganBaru: 0
            },
            growthPelangganBaru: {

            },
            dataRadius: {
                position: {
                lat: -7.8833655701050676,
                lng: 112.5246981539795
                },
                radius: 150
            },
            optionsMap: {
                streetViewControl: false,
                mapTypeControl: false,
                gestureHandling: 'greedy'
            },
            markers: [],

            invoices: {
                totalInvoice: 0,
                totalPaidInvoices: 0
            },
            persenPaid: [],
            persenGrowthInvoice: 0.00,
            persenGrowthInvoicePaid: 0.00,
            persenGrowthPelangganBaru: 0.00,
            clientgrupAdmin: [],
            viewTotalInvoice: 0,
            viewTotalPaidInvoices: 0,

            viewGrowthMountly: {
                GrowthMountlytotal: 0,
                GrowthMountlyPaided: 0,
            },
            clientGrupGrowthMountly: {
            },
            pelangganMasukDataSet: {
                viewPelangganMasuk: [],
                viewPelangganMasukEndval: 0,
                persenGrowthPelangganMasuk: 0,
            },

            statPelangganSet:{
                statHolderTotalPelangganMasuk:0,
                statHolderTotalPelangganMasukAllRegis:0,
                statHolderTotalPelangganMasukAllUnregis:0,
                statHolderTotalPelangganMasukMounthUnregis:0,
                statHolderTotalPelangganMasukMounthRegis:0,
                statHolderTotalPelangganMasukBulanIni:0,
                statHolderTotalPelangganMasukTerbaru:[],
                statHolderTotalPelangganMasukLokasi:[],
            }
           
        };


    },

    async mounted() {
        // await this.payLoadData()

    },

    async created() {
        await this.payLoadData()
    },

    methods: {

        async payLoadData() {
            await this.getPelangganBaruStat()

        },

        resetModal() {
       
        this.markers = []
         },

        async cekLat(row){
     
            this.markers = []
            this.markers.push({
            position: {
                lat: Number(this.statPelangganSet.statHolderTotalPelangganMasukLokasi.lat),
                lng: Number(this.statPelangganSet.statHolderTotalPelangganMasukLokasi.lng),
            }
            })
            this.selecetedName = row.name
            this.dataRadius.position = {
            lat:Number(this.statPelangganSet.statHolderTotalPelangganMasukLokasi.lat),
            lng: Number(this.statPelangganSet.statHolderTotalPelangganMasukLokasi.lng),
            }
            this.$refs['modal-address'].show()
            
        },
        hidemodal(){
        this.$refs['modal-address'].hide()
        this.dataRadius.position = {
            lat: -7.8833655701050676,
            lng: 112.5246981539795
        }
        },
      
        async getPelangganBaruStat(){
           
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-applicant?stat=true').then(async(response) => { 
          
           
             this.statPelangganSet.statHolderTotalPelangganMasuk = response.data.statData.holderData.total
             this.statPelangganSet.statHolderTotalPelangganBulanIni= response.data.statMountly.holderDataMontly.total
           
             this.statPelangganSet.statHolderTotalPelangganMasukTerbaru= response.data.statData.holderData.data[0]
             this.statPelangganSet.statHolderTotalPelangganMasukLokasi= response.data.statData.holderData.data[0]
            
             this.statPelangganSet.statHolderTotalPelangganMasukAllRegis = response.data.statData.RegisAll
             this.statPelangganSet.statHolderTotalPelangganMasukAllUnregis = response.data.statData.UnregisAll

             this.statPelangganSet.statHolderTotalPelangganMasukMounthRegis = response.data.statMountly.RegisMonth
             this.statPelangganSet.statHolderTotalPelangganMasukMounthUnregis = response.data.statMountly.UnregisMonth
           
                }).catch(error=>{
                    Swal.fire(
                        'Gagal!',
                        error.message,
                        'error'
                    )
                });



        },

       

    },
};


</script>

<template>
    <div class="row">
        <div class="col-md-6 col-xl-3">
            
            <div class="card" >
                <div class="card-body" style="padding-bottom: 0.625rem;padding-top: 0.625rem;">
                    <div class="float-end">
                        <apexchart class="apex-charts " dir="ltr" width="70" height="40" :options="growthChartOptions"
                            :series="series"></apexchart>
                    </div>
                    <div>
                        <h5 class="mb-0 pb-4 font-size">
                            <p class="text-muted mb-0">Total Pelanggan Masuk</p>
                           
                        </h5>
                    </div>
                    
                    
                    <!-- <h3 class="pb-0 mb-0 ">
                        <p></p>
                        <p></p>
                        <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="this.statPelangganSet.statHolderTotalPelangganMasuk"
                                    :duration="1000" ></countTo>
                                </span>
                             </h3> -->
                             <p class="pb-0 mb-0 mt-0">
                        <span data-plugin="counterup">
                            Pelanggan Masuk &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<span style="margin-left: 1.0rem;"><countTo :startVal="1" :endVal="this.statPelangganSet.statHolderTotalPelangganMasuk"
                                    :duration="500" ></countTo></span>
                                   <p class="mb-0"></p>
                            Registered Pelanggan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <span style="margin-left: 1.0rem;"><countTo :startVal="0" :endVal="this.statPelangganSet.statHolderTotalPelangganMasukAllRegis"
                                    :duration="500" ></countTo></span>
                               
                                <p class="mb-0"></p>
                            UnRegistered Pelanggan :<span style="margin-left:0.9rem;"><countTo :startVal="0" :endVal="this.statPelangganSet.statHolderTotalPelangganMasukAllUnregis"
                                    :duration="500" ></countTo></span>
                               

                                </span>   
                    </p>    
                </div>
            </div>
        
        </div>
        <div class="col-md-6 col-xl-3">
            
            <div class="card">
                <div class="card-body" style="padding-bottom: 0.625rem;padding-top: 0.625rem;">
                    <div class="float-end mr-4 mb-0 ">
                        <apexchart class="apex-charts" dir="ltr" width="80" height="40" offsetY="50" :options="chartOptions"
                            :series="series"></apexchart>
                    </div>
                    <div>
                        <h5 class="mb-0">
                            <span data-plugin="counterup">
                                <p class="text-muted pb-0 mb-1 ml-0 pr-0">Pelanggan Masuk Bulan ini</p>
                            </span>
                        </h5>
                        
                    </div>
                    <p class="pb-0 mb-0 mt-0">
                        <span data-plugin="counterup">
                            Pelanggan Masuk &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :<span style="margin-left: 1.0rem;"><countTo :startVal="1" :endVal="this.statPelangganSet.statHolderTotalPelangganBulanIni"
                                    :duration="500" ></countTo></span>
                                   <p class="mb-0"></p>
                            Registered Pelanggan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <span style="margin-left: 1.0rem;"><countTo :startVal="0" :endVal="this.statPelangganSet.statHolderTotalPelangganMasukMounthRegis"
                                    :duration="500" ></countTo></span>
                               
                                <p class="mb-0"></p>
                            UnRegistered Pelanggan :<span style="margin-left:0.9rem;"><countTo :startVal="0" :endVal="this.statPelangganSet.statHolderTotalPelangganMasukMounthUnregis"
                                    :duration="500" ></countTo></span>
                               

                                </span>   
                    </p>
                    
                </div>
            </div>
        
        </div>


        <!-- end col-->
        <div class="col-md-6 col-xl-3">
           
                <div class="card shadow  bg-white rounded pb-0"  style="height:8.25rem;" hover>
                    <div class="card-body pt-2">
                        <div class="float-end mt-0">
                                <i class="mdi mdi-account-clock mt-0" style="font-size: 2.83em;"></i>
                        </div>
                        <div>
                            <h4 class="mb-3 mt-0 pb-0">
                                <span >
                                    <p class="text-muted mb-0 font-size-17" >Pelanggan Masuk Terbaru</p>
                                </span>
                                
                            </h4>
                            <p class="pb-0 pt-2 mt-2 mb-0     bold" style=" font-family: Verdana , serif;" ><label class="mb-0 mt-0 ">Nama : {{statPelangganSet.statHolderTotalPelangganMasukTerbaru.name}}</label></p>
                            <div class="timeago pt-3 mt-0 text-left" >
                                Di Daftarkan &nbsp;: 
                                    <time-ago :datetime="statPelangganSet.statHolderTotalPelangganMasukTerbaru.createdAt" refresh tooltip="tooltip" long locale="id">
                                    </time-ago>
                                </div>
                        </div>
                    </div>
                </div>
            
        </div>
        <div class="col-md-6 col-xl-3">
            <a href="#" class="card-link">
                <div class="card shadow  bg-white rounded" href="#"  hover>
                    <div class="card-body pt-2"  @click="cekLat">
                        <div class="float-end mt-0 ">
                                <i class="mdi mdi-map-clock mt-0" style="font-size: 2.83em;"></i>
                        </div>
                        <div>
                            <h4 class="mb-3  mt-0">
                                <span >
                                    <p class="text-muted mb-0 font-size-17" >Lokasi Pelanggan Terbaru</p>
                                </span>
                                
                            </h4>
                            
                            <p class="mb-0 ">Kota&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {{statPelangganSet.statHolderTotalPelangganMasukTerbaru.kota}}</p>
                            <p class="mb-0">Kecamatan&nbsp; : {{statPelangganSet.statHolderTotalPelangganMasukTerbaru.kecamatan}}</p>
                            <p class="mb-0">Desa&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {{statPelangganSet.statHolderTotalPelangganMasukTerbaru.desa}}</p>
                        </div>
                        

                    </div>
                </div>
                </a>
        </div>
        <b-modal id="modal-center" ref="modal-address" centered title="Lokasi Pelanggan " title-class="font-18"
      @hide="resetModal">
        <gmap-map ref="gmap" :center="dataRadius.position" :zoom="14" style="height: 250px;" :options=optionsMap
          map-type-id="roadmap" >
          <gmap-marker v-for="(m, index) in markers" :key="'A' + index" :position="m.position"
            :draggable="true"></gmap-marker>
        </gmap-map>
        <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="hidemodal"
          >
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header> 
        <h4 >Lokasi Pelanggan {{statPelangganSet.statHolderTotalPelangganMasukTerbaru.name}}</h4></template>

    
    </b-modal>
    <!-- end col-->
</div>

<!-- end row--></template>
<style>

</style>