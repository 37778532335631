<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { required } from "vuelidate/lib/validators";
import modalAnalyticPelanggan from "@/components/modal-analytic-pelanggan-masuk";
import StatPelanggan from "@/components/widgets/stat-pelanggan-masuk";



export default {
  page: {
    title: "Pelanggan Masuk",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    modalAnalyticPelanggan,
    StatPelanggan,
    
  },
  computed: {
    rows() {
      return this.data.length;
    },
  },
  data() {
    return {
      title: "Pelanggan Masuk",
      items: [
        {
          text: "Pelanggan Masuk",
          href: "/",
        }
      ],
      data: [],
      submittedForm: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        {
          label: "Nama",
          key: "name",
          sortable: true,
          thStyle: { width: "20%" }
        },
        { 
          key: 'maps',
          label: '' ,
          thStyle: { width: "5%" }
        },
        {
          label: "Alamat",
          key: "address",
          sortable: true,
          thStyle: { width: "25%" }
        },
       
       
        {
          label: "Nomor Handphone",
          key: "phoneNumber",
          sortable: true,
          thStyle: { width: "20%" }
        },
        {
          key: "createdAt",
          label: "Tanggal Dibuat",
          sortable: true,
          thStyle: { width: "20%" },

          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY HH:ss")
          },
        },
        { 
          key: 'actions',
          label: '' ,
          thStyle: { width: "20%" }}
      ],
      customerApplicant: {
        name: '',
        address: '',
        phoneNumber: '62',
        clientGrup: '',
        lat:'',
        lng:'',
        provinsi:'',
        kota:'',
        kecamatan:'',
        desa:'',
      },
      isBusy: true,
      dataRadius: {
        position: {
          lat: -7.8833655701050676,
          lng: 112.5246981539795
        },
        radius: 150
      },
      statusFilter: ['Semua', 'Telah Registrasi', 'Belum Registrasi']
      ,
      statusFilterValue:"Semua",
      selecetedName:"",
      optionsMap: {
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy'
      },
      clientGrupList: [],
      markers: [],
      alamatPelanggan: {
        negara: [],
        provinsi: [],
        kota: [],
        kecamatan: [],
        desa: [],
      },
      alamatData: {
        ProvinsiTerpilih: '',
        kecamatanTerpilih:'',
        kotaTerpilih: '',
        desaTerpilih: '',
        alamatLengkap: " ",

      },
      skipQuery:0,
      filterDataBelumRegis : [],
      filterDataRegis :[],
      tableData:[],

    };
  },
 async mounted() {
   await this.getData()
   await this.getClientGrup()
   await this.choseProvinsi()
  },
  validations: {
    customerApplicant: {
      name: {
        required
      },
      address: {
        required
      },
      phoneNumber: {
        required
      },
      clientGrup: {
        required
      },

    }
  },
  methods: {
    async getData() {
      this.isBusy = true
       let limitQuery =  20
      
       if (this.statusFilterValue == 'Semua' && this.data == null){
      
       this.skipQuery=0
      }
     
    
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-applicant?$sort[createdAt]=-1&$limit='+limitQuery+'&$skip='+this.skipQuery)
        .then(response => {
       
          if (this.statusFilterValue == 'Semua' && this.data.length == 0){
            this.totalRows = response.data.allData.data.length;
            this.data=response.data.allData.data
            this.isBusy = false
           
          }
            else if(this.statusFilterValue == 'Semua' && this.data.length <= limitQuery){
             
                let a=[]
                a=response.data.allData.data
               
                const array3 = this.data.concat(a);
                this.tableData =array3
                this.data=this.tableData
               
                this.isBusy = false 
              
            }

            else {
            
                this.isBusy = false 
            return "oke"
            }
          
           
          if (this.statusFilterValue == 'Telah Registrasi'){
            this.totalRows = response.data.registedData.length;
            this.data = response.data.registedData
            this.isBusy = false
          }
          if (this.statusFilterValue == 'Belum Registrasi'){
            
            this.totalRows =  response.data.unregisData.length;
            this.data = response.data.unregisData
            this.isBusy = false
          }

          if(this.skipQuery >= response.data.allData.total)
          {
          this.skipQuery= response.data.allData.total;
          }
          else
          {
          this.skipQuery = this.skipQuery + limitQuery
         
          }
       
       
        })
       
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // if(this.statusFilterValue === 'Belum Registrasi'){
      //   this.filter=null
      // }
      // console.log("sini om");
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    registrasi(row) {
      // console.log('row', row.item)
      Swal.fire({
        title: 'Apakah anda ingin melakukan registrasi pelanggan?',
        text: "Silahkan Pastikan Data yang anda masukan tepat",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          // console.log('submit')
          this.$router.push({ path: '/customer/registration', query: { name: row.item.name, address: row.item.address, phoneNumber: row.item.phoneNumber } })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      })
    },
    openModal() {
      this.$refs['modal-customer'].show()
    },
    resetModal() {
      this.submittedForm = false
      this.customerApplicant = {
        name: '',
        address: '',
        phoneNumber: '',
        clientGrup: '',
      }
      this.markers = []
    },
    resetFormSelect(){
      this.alamatData= {
        ProvinsiTerpilih: '',
        kecamatanTerpilih:'',
        kotaTerpilih: '',
        desaTerpilih: '',
        alamatLengkap: " ",

      }
    },

    async submitCustomerApplicant() {
      
      this.submittedForm = true;
      // stop here if form is invalid
      this.$v.customerApplicant.$touch();
      if (this.$v.customerApplicant.$invalid) {
        // console.log('gagal', this.$v)
        return;
      } else {
     
        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'customer-applicant', this.customerApplicant)
          .then(async response => {
            
            // return response.data.total
            this.data =[]
            this.skipQuery=0
            await this.getData()
            this.$refs.stat.getPelangganBaruStat()
            this.resetFormSelect()
            Swal.fire("Sukses!", "Data sukses disimpan!", "success");
            this.$refs['modal-customer'].hide()
            return response
          }).catch(error => {
            console.error(error)
            Swal.fire("Gagal!", error.message, "error");
          })
      }
    },
    mark(event) {
      this.markers = []
      // console.log(event)
      this.customerApplicant.lat = event.latLng.lat()
      this.customerApplicant.lng = event.latLng.lng()
      this.markers.push({
        position: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        }
      })
    },
   
    getClientGrup() {
   
      let arrayClient = this.$store.state.authfack.user.clientgrup
      for (let index = 0; index < arrayClient.length; index++) {
        this.clientGrupList.push(arrayClient[index].code)
      }
    },
    async cekLat(row){
     
      this.markers = []
      this.markers.push({
        position: {
          lat: Number(row.lat),
          lng: Number(row.lng),
        }
      })
      this.selecetedName = row.name
      this.dataRadius.position = {
        lat:Number(row.lat),
        lng: Number(row.lng),
      }
      this.$refs['modal-address'].show()
     
    },
  
    hidemodal(){
    this.$refs['modal-address'].hide()
    this.dataRadius.position = {
          lat: -7.8833655701050676,
          lng: 112.5246981539795
      }
    },
    isChoseProvinsi() {
      
      let a = this.alamatData.ProvinsiTerpilih.id
      
      this.customerApplicant.provinsi= this.alamatData.ProvinsiTerpilih.text
      
      this.choseKota(a)
    },
    isChoseKota() {
      let a = this.alamatData.kotaTerpilih.id
      this.customerApplicant.kota= this.alamatData.kotaTerpilih.text
      this.choseKecamatan(a)
    },
    isChoseKecamatan() {
      let a = this.alamatData.kecamatanTerpilih.id
      this.customerApplicant.kecamatan= this.alamatData.kecamatanTerpilih.text
      this.choseDesa(a)
      
    },

    isChoseDesa() {
      
      this.customerApplicant.desa= this.alamatData.desaTerpilih.text
     
      
    },

    async choseProvinsi() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-provinsi').then(response => {
        this.alamatPelanggan.provinsi = response.data;
      });
      
    },

    async choseKota(parent) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-kota?parent=' + parent).then(response => {
        this.alamatPelanggan.kota = response.data;
      });
      
    },
    async choseKecamatan(parent) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-kecamatan?parent=' + parent).then(response => {
        this.alamatPelanggan.kecamatan = response.data;
        
      });

    },
    async choseDesa(parent) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'list-desa?parent=' + parent).then(response => {
        this.alamatPelanggan.desa = response.data;
        
      });

    },
    async showModalAnalytic() {
      
      this.$refs.modalAnalyticPel.show()
     
    },

    async analyticPelangganBaru(){

    },


  },

  watch:{
    'statusFilterValue': {
      async handler(newVal) {
  
     if (newVal === 'Telah Registrasi'){
      console.log("regis");
      
     }
     else if(newVal === 'Belum Registrasi'){
      this.filterOn=[]
      this.filterOn[0]='actions'
      this.filter= ''
      
     }
     await this.getData()

      },
    },

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <StatPelanggan ref="stat"/>
    <modalAnalyticPelanggan ref="modalAnalyticPel"></modalAnalyticPelanggan>
    <b-modal id="modal-center" ref="modal-customer" centered title="Form Pelanggan Masuk" title-class="font-18"
      @hide="resetModal">
      <form ref="form" @submit.prevent="submitCustomerApplicant">
        <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Nama" label-for="text">
          <b-form-input for="text" v-model="customerApplicant.name" :class="{
            'is-invalid': submittedForm && $v.customerApplicant.name.$error,
          }"></b-form-input>
          <div v-if="submittedForm && $v.customerApplicant.name.$error" class="invalid-feedback">
            <span v-if="!$v.customerApplicant.name.required">Nama tidak boleh kosong!</span>
          </div>
        </b-form-group>
        <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Nomor" label-for="text">
          <b-form-input v-mask="'62###############'" for="text" v-model="customerApplicant.phoneNumber" :class="{
            'is-invalid': submittedForm && $v.customerApplicant.phoneNumber.$error,
          }"></b-form-input>
          <div v-if="submittedForm && $v.customerApplicant.phoneNumber.$error" class="invalid-feedback">
            <span v-if="!$v.customerApplicant.phoneNumber.required">Nomor tidak boleh kosong!</span>
          </div>
        </b-form-group>
        <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Alamat" label-for="text">
          <b-form-input for="text" v-model="customerApplicant.address" :class="{
            'is-invalid': submittedForm && $v.customerApplicant.address.$error,
          }"></b-form-input>
          <div v-if="submittedForm && $v.customerApplicant.address.$error" class="invalid-feedback">
            <span v-if="!$v.customerApplicant.address.required">Alamat tidak boleh kosong!.</span>
          </div>
        </b-form-group>
             
        
         
        <b-form-group class="mb-3" id="example text" label-cols-md="2" label-cols-lg="2" label="Client Grup"
          label-for="text">
          <b-form-select for="text" v-model="customerApplicant.clientGrup" :class="{
            'is-invalid': submittedForm && $v.customerApplicant.clientGrup.$error,
          }" :options="clientGrupList" style="width: 100%; height:100% ;"></b-form-select>
          <div v-if="submittedForm && $v.customerApplicant.clientGrup.$error" class="invalid-feedback">
            <span v-if="!$v.customerApplicant.clientGrup.required">Client Grup tidak boleh kosong!.</span>
          </div>
        </b-form-group>
         <div class="row mt-5 mb-3">
            <div class="col-md-6">
            <div class="form-floating">
                        <select name="listkota" id="listkota" v-model="alamatData.ProvinsiTerpilih" class="form-select pl-2"
                          @change="isChoseProvinsi" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px); size: 50%;"
                          :class="{
                          'border border-1 border border-success' : alamatData.ProvinsiTerpilih != '',
                        }" >
                          <option id="listkota._id" v-for="provinsi in alamatPelanggan.provinsi"
                            v-bind:value="{ id: provinsi._id, text: provinsi.name }" :key="provinsi.name">
                            {{ provinsi.name }}
                          </option>
                        </select>
                        <label for="validationTooltipUsername" aria-label="Floating label select example">Provinsi*</label>
              </div>
                
            </div>
            <div class="col-md-6">
                  <div class="form-floating">
                            <select name="listkota" id="listkota" v-model="alamatData.kotaTerpilih" class="form-select pl-2"
                              @change="isChoseKota" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px); size: 50%;"
                              :class="{
                              'border border-1 border border-success' : alamatData.kotaTerpilih != '',
                              }" :disabled="alamatData.ProvinsiTerpilih === ''" >
                              <option id="listkota._id" v-for="kota in alamatPelanggan.kota"
                                v-bind:value="{ id: kota._id, text: kota.name }" :key="kota.name">
                                {{ kota.name }}
                              </option>
                            </select>
                            <label for="validationTooltipUsername" aria-label="Floating label select example">Kota*</label>
                  </div>
            </div>
           </div>
           <div class="row mt-3 mb-3">
            <div class="col-md-6">
            <div class="form-floating">
                        <select name="kecamatanTerpilih" id="kecamatanTerpilih" v-model="alamatData.kecamatanTerpilih" class="form-select pl-2"
                          @change="isChoseKecamatan" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px); size: 50%;"
                          :class="{
                          'border border-1 border border-success' : alamatData.kecamatanTerpilih != '',
                        }" :disabled="alamatData.kotaTerpilih === ''" >
                          <option id="kecamatanTerpilih._id" v-for="kecamatan in alamatPelanggan.kecamatan"
                            v-bind:value="{ id: kecamatan._id, text: kecamatan.name }" :key="kecamatan.name">
                            {{ kecamatan.name }}
                          </option>
                        </select>
                        <label for="validationTooltipUsername" aria-label="Floating label select example">Kecamatan*</label>
              </div>
                
            </div>
            <div class="col-md-6">
                  <div class="form-floating">
                            <select name="desaTerpilih" id="desaTerpilih" v-model="alamatData.desaTerpilih"  @change="isChoseDesa"
                               class="form-select pl-2" style ="height: calc(3.2rem + 2px);min-height: calc(0.5rem + 2px); size: 50%;"
                              :class="{
                              'border border-1 border border-success' : alamatData.desaTerpilih != '',
                              }" :disabled="alamatData.kecamatanTerpilih === ''" >
                              <option id="desa._id" v-for="desa in alamatPelanggan.desa"
                                v-bind:value="{ id: desa._id, text: desa.name }" :key="desa.name">
                                {{ desa.name }}
                              </option>
                            </select>
                            <label for="validationTooltipUsername" aria-label="Floating label select example">Desa*</label>
                  </div>
            </div>
           </div> 
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Latitude" label-for="formrow-email-input" class="mb-3">
              <b-form-input disabled id="formrow-email-input" type="text" v-model="customerApplicant.lat"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Longitude" label-for="formrow-password-input" class="mb-3">
              <b-form-input disabled id="formrow-password-input" v-model="customerApplicant.lng"
                type="text"></b-form-input>
            </b-form-group>
          </div>
        </div>

        <gmap-map ref="gmap" :center="dataRadius.position" :zoom="14" style="height: 250px;" :options=optionsMap
          map-type-id="roadmap" @click="mark">
          <gmap-marker v-for="(m, index) in markers" :key="'A' + index" :position="m.position"
            :draggable="true"></gmap-marker>
        </gmap-map>

      </form>
      <template #modal-footer>
        <b-button variant="primary" @click="submitCustomerApplicant()">
          Simpan
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-center" ref="modal-address" centered title="Lokasi Pelanggan " title-class="font-18"
      @hide="resetModal">
        <gmap-map ref="gmap" :center="dataRadius.position" :zoom="14" style="height: 250px;" :options=optionsMap
          map-type-id="roadmap" >
          <gmap-marker v-for="(m, index) in markers" :key="'A' + index" :position="m.position"
            :draggable="true"></gmap-marker>
        </gmap-map>
        <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="hidemodal"
          >
            Close
          </b-button>
        </div>
      </template>
      <template #modal-header> 
        <h4 >Lokasi Pelanggan Masuk {{selecetedName}}</h4></template>

    
    </b-modal>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;
                  </label>
                  <label class="d-inline-flex align-items-center" style="margin-left: 0.625rem;">
                    Filter
                    <b-form-select v-model="statusFilterValue" size="sm" :options="statusFilter"></b-form-select>&nbsp;
                  </label>
                  <label class="d-inline-flex align-items-center">
                    <b-button variant="info" size="sm" @click="showModalAnalytic"> Analytic</b-button>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div class="row justify-content-end">
                  <div class="col-3">
                    <label class="d-inline-flex align-items-center">
                      Cari:
                      <b-form-input v-model="filter" type="search" placeholder="Cari..."
                        class="form-control form-control-sm ms-2"></b-form-input>
                    </label>
                  </div>
                  <div class="col-2">
                    <div class="row justify-content-center">
                      <div class="col-4">
                        <b-button size="sm" variant="primary" @click="openModal()">
                          <i class="fas fa-plus "></i>
                        </b-button>
                      </div>
                      <div class="col-4">
                        <b-button size="sm" variant="primary" @click="getData()">
                          <i class="fas fa-redo "></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table mb-0">
              <b-table :busy="isBusy" head-variant="light" :items="data" :fields="fields" responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn" 
                @filtered="onFiltered"
                tbody-tr-class="table"
                show-empty striped>
                <template #empty>
                  <h5>Tidak ada data</h5>
                </template>
              
                <template #cell(actions)="row">
                  <b-button v-if="row.item.refId == null || row.item.refId == undefined" size="sm" variant="primary"
                    class="mb-2" @click="registrasi(row)">
                    Registrasi
                  </b-button>
                  <i v-else style="color: rgb(0, 201, 0) ;" class="fas fa-check"></i>
                </template>
                <template #cell(maps)="row">
                  <b-button v-if = "row.item.lat != null && row.item.refId == null" @click="cekLat(row.item)" size="md" variant="primary" class="mdi mdi-eye float-end" style="
                                          padding-top: 0px;
                                          padding-bottom: 0px;
                                          padding-left: 5px;
                                          padding-right: 5px;
                                          border-bottom-width: 0px;
                                          border-top-width: 0px;
                                          border-right-width: 0px;
                                          border-left-width: 0px;
                                      "></b-button>
                  <i v-else style="color: rgb(0, 201, 0) ;" class="fas fa-check"></i>
                </template>
                <template #table-busy>
                  <div class="text-center  my-2">
                    <b-spinner class="xs-1" variant="primary"></b-spinner>
                    <strong>Memuat data...</strong>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @page-click="getData" :hide-goto-end-buttons=true :limit=3></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>